import React, { useState } from "react"
import ReactPlayer from "react-player/lazy"

import Layout from "../components/layout"
import Seo from "../components/seo"
import tw from "twin.macro"

import GlitchClip from "react-glitch-effect/core/GlitchClip"

const IndexPage = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const Anchor = tw.a`font-bold border-b-2 transform inline-block hover:(border-black rotate-180)`

  const stack = [
    {
      url: "https://www.ableton.com/",
      title: "ABLETON",
    },
    {
      url: "https://www.unrealengine.com/",
      title: "UNREAL ENGINE",
    },
    {
      url: "https://obsproject.com/",
      title: "OBS",
    },
    {
      url: "https://www.python.org/",
      title: "PYTHON",
    },
    {
      url: "https://www.keyboardmaestro.com/",
      title: "KEYBOARD MAESTRO",
    },
    {
      url: "https://www.native-instruments.com/",
      title: "NATIVE INSTRUMENTS",
    },
    {
      url: "https://offworld.live/",
      title: "OFFWORLD Live",
    },
    {
      url: "https://www.spectrasonics.net/",
      title: "SPECTRASONICS",
    },
  ]

  return (
    <Layout>
      <Seo title="Home" />
      <section tw="uppercase text-lg max-w-4xl mx-auto font-space">
        <GlitchClip duration={4000}>
          <h1 tw="mb-4">
            DREAM MACHINE IS THE LOFI CHILL BEAT STATION OF THE FUTURE.
          </h1>
          <p tw="mb-4">
            A COMPUTER RIGGED UP TO WRITE MUSIC AND IMAGINE VISUALS LIVE AS IT
            BROADCASTS - PROCEDURALLY GENERATING ENDLESS TRACKS AND SCENES.
          </p>
        </GlitchClip>
        <GlitchClip duration={8000}>
          <p tw="mb-12">
            THE PROJECT WAS DESIGNED FOR IMMERSING YOURSELF IN PRODUCTIVITY OR
            RELAXATION, CHASING YOUR DREAMS OR MAKING YOUR DREAMS...
          </p>
        </GlitchClip>
        <GlitchClip duration={3000}>
          <p tw="mb-16 text-3xl md:(text-4xl)">
            ALWAYS STREAMING. NEVER REPEATING.
          </p>
        </GlitchClip>

        <div tw="grid mb-12 md:(grid-cols-2 gap-4) text-center relative">
          {!isPlaying && (
            <button
              onClick={() => setIsPlaying(!isPlaying)}
              css={[
                tw`bg-white text-[rgb(15 23 42)] hover:(bg-gray-50) w-52 h-52`,
                tw`absolute z-10  p-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2`,
              ]}
            >
              click here to start stream
            </button>
          )}

          <div css={[!isPlaying && tw`pointer-events-none`]}>
            <div tw="relative pt-[56.25%]">
              <ReactPlayer
                controls={false}
                tw="absolute inset-0"
                playing={isPlaying}
                width="100%"
                height="100%"
                url="https://www.youtube.com/watch?v=Gbbpex1Oq6I"
              />
            </div>
          </div>
          <div css={[!isPlaying && tw`pointer-events-none`]}>
            <div tw="relative pt-[56.25%]">
              <ReactPlayer
                controls={false}
                tw="absolute inset-0"
                playing={isPlaying}
                width="100%"
                height="100%"
                url="https://www.youtube.com/watch?v=hUqmY-yvnf0"
              />
            </div>
          </div>
        </div>

        <GlitchClip duration={8000}>
          <p tw="mb-4">
            TUNE IN OR SUBSCRIBE ON{" "}
            <Anchor
              href="https://www.youtube.com/c/DREAMMACHINEai"
              target="_blank"
            >
              YOUTUBE
            </Anchor>{" "}
            OR{" "}
            <Anchor
              href="https://www.twitch.tv/dreammachine_ai"
              target="_blank"
            >
              TWITCH
            </Anchor>{" "}
            FOR EASY ACCESS TO THE STREAM ANY TIME FROM ALL YOUR DEVICES - OR
            ENJOY AN AUDIO-ONLY VERSION OF THE STREAM{" "}
            <Anchor
              href="https://s2.radio.co/sf7044e5ab/listen"
              target="_blank"
            >
              HERE
            </Anchor>{" "}
            .
          </p>

          <p tw="mb-4">
            SEE THE INNER-WORKINGS OF THE MACHINE IN OUR{" "}
            <Anchor
              href="https://blog.native-instruments.com/dream-machine-interview/"
              target="_blank"
            >
              NATIVE INSTRUMENTS
            </Anchor>{" "}
            AND{" "}
            <Anchor
              href="https://www.youtube.com/watch?v=tLdIU5H-Ntw&ab_channel=Offworldlive"
              target="_blank"
            >
              OFF-WORLD LIVE
            </Anchor>{" "}
            INTERVIEWS, OR CATCH THE TEAM LIVE ON{" "}
            <Anchor href="https://discord.gg/mc8XDqWbxt" target="_blank">
              DISCORD
            </Anchor>{" "}
            OR OUR PLOT IN{" "}
            <Anchor
              href="https://play.decentraland.org/?position=-127,98"
              target="_blank"
            >
              DECENTRALAND
            </Anchor>
            .
          </p>
        </GlitchClip>
        <p tw="mb-12">
          Tune in to an archived copy of our{" "}
          <Anchor
            href="https://www.youtube.com/watch?v=LA1InPwZBnE&list=PL7dIiDC6Bp5xpKcNmn9suP4n3U0OwemdO&ab_channel=DREAMMACHINE"
            target="_blank"
          >
            Diamond City Security
          </Anchor>{" "}
          special edition stream with Kolab Project for a limited time.
        </p>
        <GlitchClip duration={6000}>
          <p tw="mb-12">
            FOLLOW US ON SOCIAL MEDIA FOR MORE -{" "}
            <Anchor
              href="https://www.instagram.com/dreammachine.ai/"
              target="_blank"
            >
              INSTAGRAM
            </Anchor>
            ,{" "}
            <Anchor
              href="https://www.tiktok.com/@dreammachine.ai"
              target="_blank"
            >
              TIKTOK
            </Anchor>
            ,
            <Anchor href="https://twitter.com/dreammachine_ai" target="_blank">
              TWITTER
            </Anchor>
            .
          </p>
        </GlitchClip>

        <GlitchClip duration={9000}>
          <h2 tw="font-bold">RIGHT NOW OUR STACK INCLUDES:</h2>
          <ul tw="mb-12">
            {stack.map((item, index) => (
              <li key={index}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>

          <h2 tw="font-bold">SPECIAL THANKS TO:</h2>
          <ul tw="mb-12">
            <li>ADAM SILVERMAN</li>
            <li>JOSHUA RICHARDS</li>
            <li>EUAN ROBERTSON</li>
            <li>MARK THOMPSON</li>
            <li>KOAL HARRISON</li>
          </ul>
        </GlitchClip>
        <h2 tw="font-bold">
          MADE BY{" "}
          <Anchor href="https://spaceagency.work/" target="_blank">
            SPACE AGENCY
          </Anchor>{" "}
          AND{" "}
          <Anchor href="https://www.instagram.com/memorecks/" target="_blank">
            MEMORECKS
          </Anchor>
        </h2>
        <p tw="mb-4">BUILD V2.4 01/12/2023</p>
        <p>
          <a href="mailto:info@spaceagency.work">/contact</a>
        </p>
      </section>
    </Layout>
  )
}

export default IndexPage
